<template src='./ModalResponse.html'>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./ModalResponse.css">
</style>

<script>

import Dialog from 'primevue/dialog';
import axios from 'axios'
import env from  '../../../envioments'
import { BFormInvalidFeedback } from 'bootstrap-vue';
import ConsultationService  from '../../../service/ConsultationService'
import RealTime, {NotificationService} from "../../../service/RealTime";


export default  {
  name:'ModalResponse',
  components:
  {
      Dialog,
      BFormInvalidFeedback
  },


  data()
  {
    return {
      modalform : false,
      service:null,
      model:{
        respuesta:null
      },
      consultationModel:null,

    }

  },
  created(){
    this.service = ConsultationService;
    // console.log("iniciao modal")
    this.cleanForm();
  },
  methods:
  {
    open_modal(consultation)
    {
      // console.log(consultation)
      this.consultationModel = consultation;
      this.modalform = true;
      this.cleanForm();

    },
    cleanForm()
    {
      this.model = {
        respuesta:null
      };
    },
    submitx()
    {
      this.$refs.observer.validate();
        if(!this.$refs.observer.flags.invalid){
            this.submitData();
        }
    },

    submitData()
    {
      axios.patch( `${env.remoteUrl}/admin/consultations/${this.consultationModel.id}/response`,this.model)
      .then(() => {
        NotificationService.Notificate('ok');
        this.resetTable();
        this.modalform = false;
      })
    },

    resetTable()
    {
      this.$emit('resetTable')
    }

  }
}
</script>
