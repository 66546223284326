<template>
    <div id="content">
        <DataTable :value="data"
                   class="p-datatable-responsive"
                   selectionMode="single"
                   dataKey="id"
                   :paginator="true"
                   :rows="filters.perPage"
                   :filters="filters"
                   :totalRecords="filters.totalRows"
                   :loading="loading" @page="onPage($event)" :lazy="true"
                   :scrollable="true"
                   scrollHeight="calc(100vh - 310px)"
                   :stickyHeader="true"

                   id="table"
        >
        <!-- @row-select="questionMarkWitApprove" -->
            <template #empty>
                <h6 class="text-center mt-2" v-if="!loading">No se encontraron registros que coincidan con la búsqueda</h6>
                <h6 class="text-center mt-2"  v-else>Buscando</h6>
            </template>
            <template #header>
                <div class="d-flex justify-content-between">
                    <h5 class="text-left">Consultas</h5>
                    <div>
                        <Button title="Buscar" icon="pi pi-search" @click="reload()" class="mx-2"/>
                        <Button class="btn btn-company " @click="clearFilters()" label="Limpiar"/>
                    </div>



                </div>
            </template>
            <Column field="dni" header="DNI" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">DNI</span>
                    {{slotProps.data.user.dni}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.dni" class="p-column-filter" placeholder="DNI" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="name" header="Nombres" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Nombre</span>
                    {{slotProps.data.person.NOMBRES}}

                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.NOMBRES" class="p-column-filter" placeholder="Por nombres" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="apaterno" header="Apellido paterno" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Apellido materno</span>
                    {{slotProps.data.person.apaterno}}

                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.apaterno" class="p-column-filter" placeholder="Por apellido paterno" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="amaterno" header="Apellido materno" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Apellido materno</span>
                    {{slotProps.data.person.amaterno}}

                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.amaterno" class="p-column-filter" placeholder="Por apellido materno" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="email" header="Correo electrónico" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Correo electrónico</span>
                    {{slotProps.data.user.email}}

                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.email" class="p-column-filter" placeholder="Por email" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="CELULAR" header="Celular" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Celular</span>
                    {{slotProps.data.person.CELULAR}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.CELULAR" class="p-column-filter" placeholder="Por celular" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="created_at" header="Fecha de consulta" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Fecha de consulta</span>
                    {{slotProps.data.created_at  | moment("DD/MM/YYYY hh:mm a")}}
                </template>
                <template #filter>
                    <Calendar :showButtonBar="true" :locale="es" dateFormat="dd/mm/yy"    hourFormat="12" appendTo="body" @date-select="changeDate($event)" @clear-click="changeDate(null)"/>

                </template>
            </Column>
            <!-- <Column field="type" header="Tipo" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Tipo</span>
                    {{slotProps.data.type.name}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.consultation_type" class="p-column-filter" placeholder="Buscar por tipo de consulta" @keyup.enter.native="reload()"/>

                </template>
            </Column> -->
            <Column field="consultation" header="Consulta" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Consulta</span>
                    {{slotProps.data.consultation}}
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters.consultation" class="p-column-filter" placeholder="Buscar por consutla" @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="is_solved" header="Estado" :sortable="false"  headerStyle="width: 8em">
                <template #body="slotProps">
                    <div class="text-center">
                        <span class="p-column-title">Estado</span>
                        {{slotProps.data.is_solved ? 'Atendido' : 'Pendiente' }}
                    </div>

                </template>
                <template #filter>
                    <b-form-select  v-model="filters.is_solved" :options="solved"
                                    value-field="id"
                                    text-field="title"
                                    @change="reload"
                    ></b-form-select>

                </template>
            </Column>
            <Column field="respuesta" header="Respuesta" :sortable="false"  headerStyle="width: 8em">
                <template #body="slotProps">
                    <div class="text-center">
                        <span class="p-column-title">Estado</span>
                        {{slotProps.data.respuesta }}
                    </div>

                </template>
                <template #filter>
                    <InputText type="text"
                    v-model="filters.Respuesta"
                    class="p-column-filter"
                    placeholder="Buscar por Respuesta"
                    @keyup.enter.native="reload()"/>

                </template>
            </Column>
            <Column field="fecha_respuesta" header="Fecha Respuesta" :sortable="false">
                <template #body="slotProps">
                    <span class="p-column-title">Fecha Respuesta</span>
                    {{slotProps.data.fecha_respuesta  | moment("DD/MM/YYYY hh:mm a")}}
                </template>
                <template #filter>
                    <Calendar :showButtonBar="true" :locale="es" dateFormat="dd/mm/yy"    hourFormat="12" appendTo="body" @date-select="changeDateResponse($event)" @clear-click="changeDateResponse(null)"/>

                </template>
            </Column>
            <Column field="id" header="Acciones" :sortable="false" headerStyle="width: 6rem" bodyClass="action">
                <template #body="slotProps" class="tex">

                    <Button v-if="!slotProps.data.is_solved" title="Responder mensaje" icon="pi pi-comment" @click="response(slotProps.data)" class="m-auto"/>


                </template>
                <template #filter>
                    <button class="btn btn-company btn-sm" @click="clearFilters()">limpiar</button>

                </template>

            </Column>

        </DataTable>

        <!-- CGNR 06/01/2021 -->

        <!-- <Dialog header="Pregunta" :visible.sync="question"   :modal="true">
           ¿Deseas marca esta consulta como solucionada?
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="question = false" class="p-button-secondary"/>
                <Button label="Si" icon="pi pi-check" @click="approve()"/>
            </template>
        </Dialog> -->

         <!-- CGNR 06/01/2021 -->
        <div>
          <ModalResponse-Component
            v-on:resetTable="reload"
            ref="modalResponse">
          </ModalResponse-Component>
        </div>

    </div>



</template>

<script>

    import Crud from '../../service/Crud';
    //import Dialog from 'primevue/dialog';
    import Calendar from 'primevue/calendar';
    import toDbDate from "../../tools/toDbDate";
    import ModalResponseComponent from './ModalResponse/ModalResponse';

    export default {

        components: {
            //Dialog,
            Calendar,
            ModalResponseComponent
        },
        data() {
            return {
                es: {
                    firstDayOfWeek: 1,
                    dayNames: [ "Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado" ],
                    dayNamesShort: [ "Dom","Lun","Mar","Mié","Jue","Vie","Sáb" ],
                    dayNamesMin: [ "D","L","M","X","J","V","S" ],
                    monthNames: [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ],
                    monthNamesShort: [ "Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic" ],
                    today: 'Hoy',
                    clear: 'Borrar',
                    weekHeader: 'Sm'
                },
                filters: {
                    page:1,
                    perPage: 20,
                    totalRows:0,
                    is_solved:-1
                },
                loading:false,
                question:false,
                data: [],
                layout: 'list',
                sortKey: null,
                sortOrder: null,
                sortField: null,
                crud: null,
                selected:null,
                solved:[
                    {
                        id: -1,
                        title:'Todo'
                    },
                    {
                        id:1,
                        title:'Atendido'
                    },
                    {
                        id:0,
                        title:'Pendiente'
                    }
                ]
            }

        },
        created() {
            this.crud = Crud('consultations');
            this.crud.findAll(this.filters).then(res =>{
                this.data = res.data ;
                this.filters.totalRows = res.meta.total;
            });
        },
        mounted() {
        },
        methods: {
            clearFilters() {
                const omit  = ['perPage', 'totalRows', 'page'];
                Object.keys(this.filters).map(((value) => { if( omit.indexOf(value) === -1){ this.filters[value] = null} }));
                this.reload();
            },
            changeDate(event){
                 this.filters.created_at = event ? toDbDate(event): null;
                 this.reload();
            },

            //INICIO CGNR 06/01/2021
            changeDateResponse(event)
            {
              this.filters.fecha_respuesta = event ? toDbDate(event): null;
              this.reload();
            },
            //INICIO CGNR 06/01/2021
            reload(){
                this.loading = true;
                this.crud.findAll(this.filters).then(res =>{
                    this.data = res.data ;
                    this.filters.totalRows = res.meta.total;
                    this.loading = false;

                });
            },
            onPage(event){
                this.filters.page  = event.page + 1;
                this.reload();
            },
            //INICIO CGNR 6/01/2021

            // questionMarkWitApprove(item){
            //     this.selected = item;
            //     this.question = true
            // },

            //FIN CGNR 6/01/2021
            approve() {
                this.crud.patch(this.selected.data.id, 'solved').then(() => {this.reload();this.question = false});
            },
            ///CGNR RESPONSE MENSAJE
            response (consultation)
            {

              this.$refs.modalResponse.open_modal(consultation);
            }
            ////FIN CGNR

        }
    }
</script>

<style>
    .p-dropdown-panel{
        margin-left: 15px;
    }
</style>
<style lang="scss" scoped>


    .p-column-filter {
        margin-top: .5em;
    }

    /deep/ .p-dropdown {
        width: 12em;
    }

    /deep/ .p-dataview {
        .car-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2em;

            & > div {
                display: flex;
                align-items: center;

                img {
                    margin-right: 14px;
                }
            }
        }

        .car-detail {
            padding: 0 1em 1em 1em;
            margin: 1em;
        }
    }

    .p-carousel {
        .p-carousel-content {
            .p-carousel-item {
                .car-details {
                    > .p-grid {
                        border: 1px solid #b3c2ca;
                        border-radius: 3px;
                        margin: .3em;
                        text-align: center;
                        padding: 2em 0 2.25em 0;
                    }
                }

                .car-data {
                    .car-title {
                        font-weight: 700;
                        font-size: 20px;
                        margin-top: 24px;
                    }

                    .car-subtitle {
                        margin: .25em 0 2em 0;
                    }

                    button {
                        margin-left: .5em;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .p-dataview {
            .car-details {
                img {
                    width: 75px;
                }
            }
        }
    }

    .p-dropdown-car-option {
        img {
            vertical-align: middle;
            margin-right: .5em;
            width: 24px;
        }

        span {
            float: right;
            margin-top: .125em;
        }
    }
</style>
