import axios from 'axios'
import env from  '../envioments'

export class ConsultationService
{
  response (id, mensaje)
  {
    return axios.post( `${env.remoteUrl}/admin/consultations/${id}/response`,mensaje)

  }
}


export default () => {
  return new ConsultationService()
};
